
import { defineComponent, ref } from 'vue'
import TmModal from '@/components/shared/modal/TmModal.vue'
import TmButton from '@/components/shared/TmButton.vue'
import TmFormLine from '@/components/shared/TmFormLine.vue'
import TmStatus from '@/components/shared/TmStatus.vue'

export default defineComponent({
  components: {
    TmButton,
    TmStatus,
    TmModal,
    TmFormLine,
  },
  props: {
    subAccounts: {
      type: Boolean,
    },
  },
  setup() {
    const email = ref()
    const role = ref('admin')

    return {
      email,
      role,
    }
  },
})
